<template>
   <el-col class="cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>营销云</el-breadcrumb-item>
            <el-breadcrumb-item>转换</el-breadcrumb-item>
            <el-breadcrumb-item>酒店业务二次营销</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>

      <el-row class="secondarymarketing">
         <el-row class="funnel">
            <el-row class="funnel-title">二次营销线上转化漏斗<i class="el-icon-refresh" @click="infundibulum()"></i ></el-row>
            <el-row class="row-data-box">
               <el-col :span="12">过去7日</el-col>
               <el-col :span="12">
                  <date-packer :datePacker="orderDateVal" @setDatePacker="getOrderDatePacker"></date-packer>
               </el-col>
            </el-row>
            <el-row id="echa" class="echa-box" style="width: 85%; height: 400px;"></el-row>
         </el-row>
         <el-row class="funnel">
            <el-row class="funnel-title">各门店复购客户数 <i class="el-icon-refresh" @click="After()"></i></el-row>
            <el-row class="row-data-box">
               <el-col :span="12">过去7日</el-col>
               <el-col :span="12">
                  <date-packer :datePacker="orderDateVals" @setDatePacker="getOrderDatePackers"></date-packer >
               </el-col>
            </el-row>
            <el-row id="Afterpurchase" class="echa-box" style="width: 80%; height: 400px;margin-bottom: 25px;"></el-row>
         </el-row>
      </el-row>

      <el-row class="secondarymarketing">
         <el-row class="funnel">
            <el-row class="funnel-title">活跃老客户数<i class="el-icon-refresh" @click="getWeekActiveUser()"></i></el-row>
            <el-row class="row-data-box">
               <el-col :span="12">过去7日</el-col>
               <el-col :span="12">
                  <date-packer :datePacker="orderDateVala"  @setDatePacker="getOrderDatePackera"></date-packer >
               </el-col>
            </el-row>
            <el-table :data="activeData" style="width: 100%;margin-top: 20px;">
               <el-table-column prop="statisticsDay" label="日期"></el-table-column>
               <el-table-column prop="totalConsumptionCount" label="启动的次数"></el-table-column>
            </el-table>
         </el-row>
         <el-row class="funnel">
            <el-row class="funnel-title">老客户订单销量<i class="el-icon-refresh" @click="singlepassengerSalesa()"></i></el-row>
            <el-row class="row-data-box">
               <el-col :span="12">过去7日</el-col>
               <el-col :span="12">
                  <date-packer :datePacker="orderDateValar" @setDatePacker="getOrderDatePackerar"></date-packer>
               </el-col>
            </el-row>
            <el-table :data="tableData" style="width: 100%;margin-top: 20px;">
               <el-table-column prop="date" label="日期"></el-table-column>
               <el-table-column prop="saleCount" label="老客户单客销售量" ></el-table-column>
            </el-table>
         </el-row>
      </el-row>
   </el-col>
</template>

<script>
import { mapState } from "vuex";
import { urlObj } from "@/api/interface";
import { dashboard } from '@/api/interface/smtech'
import {dateFactory} from "@/common/js/common";
export default {
   computed:{
      ...mapState(['hotelInfo'])
   },
   watch:{
      hotelInfo:{
         handler(newVal,oldVal){
            if(newVal.id !== oldVal.id && oldVal){
               this.hotelId = this.hotelInfo.id
               this.timead();
            }
         }
      }
   },

   data() {
      return {
         orderDateVal: [],
         orderDateVals: [],
         orderDateVala: [],
         orderDateValar: [],
         activeData: [],
         option : {
            color: ['#3398DB'],
            tooltip: {
               trigger: 'axis',
               axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                  type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
               }
            },
            grid: {
               left: '3%',
               right: '4%',
               bottom: '3%',
               containLabel: true
            },
            xAxis: [
               {
                  type: 'category',
                  data: [],
                  axisTick: {
                     alignWithLabel: true
                  }
               }
            ],
            yAxis: [
               {
                  type: 'value',
                  minInterval:[]
               }
            ],
            series: [
               {
                  name: '直接访问',
                  type: 'bar',
                  barWidth: '60%',
                  data: []
               }
            ]
         },
         tableData: [],
         purchase: {
            tooltip: {
               trigger: "axis",
            },
            legend: {
               data: [],
            },
            grid: {
               left: "3%",
               right: "4%",
               bottom: "3%",
               containLabel: true,
            },
            toolbox: {
               feature: {
                  saveAsImage: {},
               },
            },
            xAxis: {
               type: "category",
               boundaryGap: false,
               data: [],
            },
            yAxis: {
               type: "category",
            },
            series: [
               {
                  name: "",
                  type: "line",
                  stack: "总量",
                  data: [],
                  smooth: true,
                  itemStyle: {
                     normal: {
                        color: "#ea4733", //折线点的颜色
                        lineStyle: {
                           color: "#ea4733", //折线的颜色
                        },
                     },
                  },
                  textStyle: {
                     fontSize: 12,
                     color: "#ea4733",
                  },
               },
               {
                  name: "",
                  type: "line",
                  stack: "总量",
                  data: [],
                  smooth: true,
                  itemStyle: {
                     normal: {
                        color: "#389be6", //折线点的颜色
                        lineStyle: {
                           color: "#389be6", //折线的颜色
                        },
                     },
                  },
                  textStyle: {
                     fontSize: 12,
                     color: "#ea4733",
                  },
               },
            ],
         },
         orderBeginTime: "",
         orderEndTime: "",
         orderBeginTimeas: "",
         orderEndTimeas: "",
         date2:'',
         // hotelId:'',
      };
   },
   mounted() {
      this.date2 = dateFactory.dateFormat(false)
      let times = dateFactory.getDistanceAppoint(this.date2, '-', 7, false);
      this.orderDateValar = [times,this.date2]
      this.orderDateVal = [times,this.date2]
      this.orderDateVall = [times,this.date2]
      this.orderDateVals = [times,this.date2]
      this.orderBeginTimes =  this.orderBeginTime = this.orderBeginTimesa = this.orderBeginTimer = times
      this.orderEndTime  =  this.orderEndTimes  = this.orderEndTimesa = this.orderEndTime = this.date2
      this.singlepassengerSalesa();
      this.infundibulum();
      this.After();
      this.getWeekActiveUser()
   },
   methods: {

      timead() {
         // let date1 = moment(new Date()).format("YYYY-MM-DD");
         // var times = date1;
         this.orderBeginTime = this.date2;
         // this.orderEndTime = times;
         this.orderBeginTimes = this.date2;
         // this.orderEndTimes = times;
         this.orderBeginTimesa = this.date2;
         // this.orderEndTimesa = times;
      },

      //单客销售
      singlepassengerSalesa() {
         const url = urlObj.getRegularCustomerOrderStatistics;
         const param = {
            hotelId: this.hotelInfo.id,
            beginTime: this.orderBeginTimesa,
            endTime: this.orderEndTimesa,
         };
         this.$axios.get(url, param).then((res) => {
            // console.log(res)
            this.tableData = res.records;
         });
      },
      // 获取周活跃老客户
      getWeekActiveUser() {
         const url = dashboard.weekActiveUser;
         const param = {
            hotelId: this.hotelInfo.id,
            source: 'WECHAT_OFFICIAL',
            date: "WEEK",
            statisticsDay: this.statisticsDay2,
         };
         this.$axios.post(url, param).then((res) => {
            // console.log(res);
            this.activeData = res.records.marketingDTOList
         });
      },
      // 二次营销转化
      getOrderDatePacker(val) {
         this.orderDateVal = val;
         if (this.orderDateVal) {
            this.orderBeginTimesa = this.orderDateVal[0].split(" ")[0];
            this.orderEndTimesa = this.orderDateVal[1].split(" ")[0];
         } else {
            this.orderBeginTimesa = "";
            this.orderEndTimesa = "";
         }
         this.infundibulum();
      },
      // 复购数
      getOrderDatePackers(val) {
         this.orderDateVals = val;
         if (this.orderDateVals) {
            this.orderBeginTime = this.orderDateVals[0].split(" ")[0];
            this.orderEndTime = this.orderDateVals[1].split(" ")[0];
         } else {
            this.orderBeginTime = "";
            this.orderEndTime = "";
         }
         this.After();
      },
      // 老客户数
      getOrderDatePackera(val) {
         this.orderDateVala = val;
         if (this.orderDateVala) {
            this.orderBeginTime = this.orderDateVala[0].split(" ")[0];
            this.orderEndTime = this.orderDateVala[1].split(" ")[0];
         } else {
            this.orderBeginTime = "";
            this.orderEndTime = "";
         }
      },
      // 老客户订单销量
      getOrderDatePackerar(val) {
         this.orderDateValar = val;
         if (this.orderDateValar) {
            this.orderBeginTimeas = this.orderDateValar[0].split(" ")[0];
            this.orderEndTimeas = this.orderDateValar[1].split(" ")[0];
         } else {
            this.orderBeginTimesa = "";
            this.orderEndTimesa = "";
         }
         this.singlepassengerSalesa();
      },

      infundibulum() {
         this.option.xAxis[0].data = []
         this.option.series[0].data = []
         var myChart = this.$echarts.init(document.getElementById("echa"));
         const url = urlObj.getSecondRate;
         const param = {
            hotelId: this.hotelInfo.id,
            beginTime: this.orderBeginTimesa+' 00:00:00',
            endTime: this.orderEndTimesa+' 00:59:59',
         };
         this.$axios.get(url, param).then((res) => {
            // console.log(res);
            let arr = []
            arr = res.records
            arr.forEach(item=>{
               // console.log(item)
               this.option.xAxis[0].data.push(item.eventName)
               this.option.series[0].data.push(item.buyCount)
            })
            this.option.yAxis[0].minInterval.push(1)
            myChart.setOption(this.option, true);
         });
      },

      After() {
         var myChart = this.$echarts.init(
               document.getElementById("Afterpurchase")
         );
         const url = urlObj.repurchasesStatistics;
         const param = {
            hotelId: this.hotelInfo.id,
            source: 'WECHAT_OFFICIAL',
            beginTime: this.orderBeginTime+' 00:00:00',
            endTime: this.orderEndTime+' 00:59:59',
         };
         this.$axios.get(url, param).then((res) => {
            // console.log(res);
            // let datas = []
            let data = res.records;
            let obj = { name: '', data: [] }
            this.purchase.xAxis.data = []
            for (let item in data) {
               this.purchase.legend.data.push(item)
               this.purchase.xAxis.data.push(item)
               obj.name = item
            }
            if (data.hasOwnProperty('测试商户711') && data.测试商户711.length !=0 ){
               data.测试商户711.forEach(item=>{
                  this.purchase.series[0].data.push(item.buyCount)
                  this.purchase.series[0].name = item.storeName
                  // datas.push(item.storeName)
                  // this.purchase.xAxis.data = []
               })
            }
            if (data.hasOwnProperty('测试商户711') && data.测试商户711.length !=0 ){
               data.测试用商城商家.forEach(item=>{
                  this.purchase.series[1].data.push(item.buyCount)
                  this.purchase.series[1].name = item.storeName
               })
            }
            // console.log(datas)

            // console.log(this.purchase.legend.data);
            // let dateandtime = [];
            // let dateandtimes = [];
            // let dateandtimesa = [];
            // let dateandtimesb = [];
            // let arrsay = [];
            // let arrsab = [];
            // let arra = [];
            // let arrsa = [];
            // let uname = "";
            // let unames = "";
            // let unamea = "";
            // let unameb = "";
            // let arrsas = [];
            // arr.测试商户711.forEach((item) => {
            //   console.log(item);
            //   arrsa.push(item.dayQuantity);
            //   dateandtime.push(item.date);
            // });
            // arr.测试用商城商家.forEach((item) => {
            //   // console.log(item);
            //   arrsas.push(item.dayQuantity);
            //   dateandtimes.push(item.date);
            // });
            // arr.深圳市家乐福超市.forEach((item) => {
            //   // console.log(item)
            //   arrsay.push(item.dayQuantity);
            //   dateandtimesa.push(item.date);
            // });
            // this.purchase.series[0].data = arrsa;
            // this.purchase.series[0].name = uname;
            // this.purchase.series[1].data = arrsas;
            // this.purchase.series[1].name = unames;
            // this.purchase.series[2].data = arrsay;
            // this.purchase.series[2].name = unamea;
            // this.purchase.series[3].data = arrsab;
            // this.purchase.series[3].name = unameb;
            //
            // this.purchase.xAxis.data = dateandtime;
            // // console.log(this.purchase)
            myChart.setOption(this.purchase, true);
            // return datas
         });
      },
   },
};
</script>

<style scoped  lang="scss">
.secondarymarketing{
   display: flex;
   height: auto;
   margin: 0px 20px;
   margin-bottom: 20px;
   .funnel{
      width: 49%;
      background-color: white;
      box-shadow: 0px 0px 20px #dce7f9;
      .funnel-title{
         height: 50px;
         line-height: 50px;
         background: #f7f7f8;
         padding: 0px 20px;
         i{
            float: right;
            display: inline-block;
            font-size: 20px;
            padding: 1rem;
         }
      }
      .row-data-box{
         height: 50px;
         line-height: 50px;
         padding: 0px 20px;
      }
      .echa-box{
         padding: 0px 20px;
      }
   }
   .funnel:last-child{
      margin-left: 2%;
   }
}
.formerly {
   font-size: 14px;
   float: left;
   padding-left: 1rem;
}
.tiem {
   float: right;
}
.box {
   margin-top: 2rem;
}
.totality span {
   display: inline-block;
   width: 10px;
   height: 10px;
   background: #4a90e2;
   border-radius: 50%;
   margin-left: 5px;
}
.box .el-row {
   //font-size: 14px;
   //width: 49%;
   //color: #52a0ff;
}
.totality {
   font-size: 17px !important;
   float: right;
   color: #ff5f5f !important;
}
#Afterpurchase {
   position: relative;
   top: 30px;
}
.bottom {
   width: 98%;
   margin: auto;
   height: 500px;
   margin-top: 1rem;
}
.Activeoldcustomers {
   width: 49%;
   height: 100%;
   box-shadow: 0px 0px 20px #dce7f9;
   float: left;
}
.Singlepassengersales {
   width: 49%;
   height: 100%;
   box-shadow: 0px 0px 20px #dce7f9;
   float: right;
}
/deep/ .has-gutter tr th {
   text-align: center;
   border-bottom: 2px solid #399eff;
}
/deep/ tbody .el-table__row td {
   text-align: center;
   border-bottom: 1px solid #d5d6d8;
}
@media screen and (max-width: 1700px) {
   .main {
      width: 1300px;
   }
}
</style>
